import { FC, SVGProps } from 'react'

export const Bell: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.93555 16.7821H9.03934C9.09208 18.2134 10.2673 19.5393 12 19.5393C13.7252 19.5393 14.9079 18.2285 14.9682 16.7821H18.0645C18.8479 16.7821 19.3301 16.3527 19.3301 15.7048C19.3301 14.8987 18.5993 14.2132 17.9138 13.5578C17.3789 13.0379 17.2508 11.9983 17.1529 10.9361C17.0474 8.09598 16.2112 6.19754 14.2525 5.4894C13.9587 4.48744 13.1225 3.72656 12 3.72656C10.8775 3.72656 10.0413 4.48744 9.75502 5.4894C7.78878 6.19754 6.9601 8.09598 6.8471 10.9361C6.74916 11.9983 6.62109 13.0379 6.08622 13.5578C5.40067 14.2132 4.66992 14.8987 4.66992 15.7048C4.66992 16.3527 5.15206 16.7821 5.93555 16.7821ZM6.50809 15.3809V15.2905C6.70396 15.072 7.1409 14.6727 7.51004 14.2508C8.02986 13.6557 8.27093 12.5558 8.34626 11.1244C8.43666 8.10352 9.37081 7.0865 10.5762 6.76256C10.7645 6.70982 10.8624 6.61942 10.8775 6.41602C10.9152 5.65513 11.3446 5.14286 12 5.14286C12.6629 5.14286 13.0848 5.65513 13.1225 6.41602C13.1376 6.61942 13.243 6.70982 13.4238 6.76256C14.6367 7.0865 15.5633 8.10352 15.6537 11.1244C15.7366 12.5558 15.9777 13.6557 16.49 14.2508C16.8591 14.6727 17.281 15.072 17.4768 15.2905V15.3809H6.50809ZM12 18.3265C11.1487 18.3265 10.5385 17.7162 10.4858 16.7821H13.5218C13.4766 17.7087 12.8588 18.3265 12 18.3265Z"
      fill="black"
    />
  </svg>
)

export const BellWithBadge: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.93555 16.7811H9.03934C9.09208 18.2125 10.2673 19.5384 12 19.5384C13.7252 19.5384 14.9079 18.22 14.9682 16.7811H18.0645C18.8479 16.7811 19.3301 16.3517 19.3301 15.7038C19.3301 14.8977 18.5993 14.2122 17.9138 13.5568C17.3864 13.037 17.2659 12.0124 17.1604 10.9879C17.1529 10.566 17.1228 10.1667 17.0776 9.78251C16.6256 9.97084 16.0605 10.0462 15.5784 9.97838C15.6161 10.3249 15.6462 10.6941 15.6537 11.1235C15.7291 12.5548 15.9777 13.6547 16.49 14.2499C16.8591 14.6717 17.281 15.071 17.4768 15.2895V15.3799H6.50809V15.2895C6.70396 15.071 7.1409 14.6717 7.51004 14.2499C8.02986 13.6547 8.27093 12.5548 8.34626 11.1235C8.43666 8.10254 9.36328 7.07799 10.5762 6.76158C10.7645 6.70884 10.8624 6.61844 10.8775 6.41504C10.9227 5.65416 11.3446 5.14188 12 5.14188C12.1281 5.14188 12.2411 5.15695 12.3465 5.19461C12.4821 4.73507 12.7232 4.32073 13.0396 3.97419C12.7307 3.81599 12.3842 3.72559 12 3.72559C10.8775 3.72559 10.0413 4.48647 9.75502 5.48842C7.78878 6.19657 6.9601 8.09501 6.8471 10.9351C6.74916 11.9973 6.62109 13.037 6.08622 13.5568C5.40067 14.2122 4.66992 14.8977 4.66992 15.7038C4.66992 16.3517 5.15206 16.7811 5.93555 16.7811ZM12 18.3255C11.1487 18.3255 10.5385 17.7153 10.4858 16.7811H13.5218C13.4766 17.7077 12.8588 18.3255 12 18.3255Z"
      fill="black"
    />
    <circle cx="15.8" cy="6.6" r="3" fill="red" />
  </svg>
)

export const BellSlash: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="59"
    viewBox="0 0 56 59"
    fill="#AEAEB2"
    {...props}
  >
    <path d="M15.4289 9.1687L19.3789 13.1188C20.2628 12.4834 21.2296 12.0691 22.2517 11.7929C22.9422 11.5995 23.329 11.268 23.3842 10.5222C23.5223 7.73232 25.0692 5.85397 27.4724 5.85397C29.9032 5.85397 31.4501 7.73232 31.5882 10.5222C31.6434 11.268 32.0301 11.5995 32.6931 11.7929C37.1956 13.0083 40.6761 16.8478 40.9523 28.3665C41.1457 31.5431 41.4219 33.6977 42.2506 35.6589L53.2997 46.929C53.9074 46.2661 54.2665 45.3821 54.2665 44.3877C54.2665 41.8741 52.0014 39.6919 49.8192 37.6478C47.4989 35.217 46.7531 32.3994 46.4492 27.8693C46.1178 16.765 42.9688 9.72116 35.7316 7.12462C34.6819 3.45079 31.5882 0.660889 27.4724 0.660889C23.3566 0.660889 20.2905 3.45079 19.2408 7.12462C17.8044 7.62183 16.5338 8.3124 15.4289 9.1687ZM1.70034 6.57216L51.9185 56.7351C52.7748 57.619 54.2112 57.619 55.0399 56.7351C55.8686 55.8788 55.8962 54.4977 55.0399 53.6414L4.82171 3.45079C3.96541 2.59448 2.55665 2.59448 1.70034 3.45079C0.844033 4.27947 0.844033 5.74348 1.70034 6.57216ZM27.4724 58.6411C33.3837 58.6411 37.5823 54.3872 38.2453 49.4151H39.7093L33.6599 43.3933H7.33538V43.0618C8.05358 42.2608 9.6557 40.7968 11.0092 39.2499C12.8323 37.1782 13.7162 33.6701 13.9648 28.6428C14.0201 26.9578 14.1582 25.4385 14.3516 24.0574L9.51758 19.2234C8.96513 21.5161 8.6889 24.1679 8.57841 27.0959C8.21931 30.9907 7.77735 34.8026 5.78851 36.7086C3.27484 39.1118 0.595428 41.6255 0.595428 44.5811C0.595428 46.9566 2.36329 48.5311 5.26368 48.5311H16.6166C16.8376 53.8071 21.1744 58.6411 27.4724 58.6411ZM27.4724 54.1938C24.3786 54.1938 22.1136 51.9564 21.9202 48.5311H33.0522C32.8588 51.9564 30.5938 54.1938 27.4724 54.1938Z" />
  </svg>
)
