import { FC, SVGProps } from 'react'

export const Moon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4795 5.1609C12.5467 4.94782 12.5433 4.71103 12.4534 4.48755C12.2474 3.97514 11.665 3.72677 11.1526 3.93281C6.37546 5.85366 3 10.5308 3 15.9987C3 23.1784 8.8203 28.9987 16 28.9987C21.4719 28.9987 26.1519 25.6183 28.0701 20.8356C28.2757 20.323 28.0268 19.7408 27.5142 19.5352C27.2306 19.4215 26.9256 19.4468 26.675 19.5791C25.4343 20.0648 24.0828 20.3319 22.6667 20.3319C16.5915 20.3319 11.6667 15.407 11.6667 9.3319C11.6667 7.88779 11.9444 6.511 12.4485 5.25027C12.4603 5.22068 12.4707 5.19087 12.4795 5.1609ZM25.1591 22.093C24.352 22.2498 23.5186 22.3319 22.6667 22.3319C15.487 22.3319 9.66667 16.5116 9.66667 9.3319C9.66667 8.48003 9.74873 7.64671 9.90551 6.83965C6.94732 8.81205 5 12.1788 5 15.9987C5 22.0738 9.92487 26.9987 16 26.9987C19.82 26.9987 23.1867 25.0513 25.1591 22.093Z"
      fill="black"
    />
  </svg>
)
