import { FC, SVGProps } from 'react'

export const Interest: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="128"
    height="40"
    viewBox="0 0 128 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.9069 27.712H3.02688V23.52L12.8509 9.6H17.6189V24.512H20.3389V27.712H17.6189V32H13.9069V27.712ZM14.0349 24.608V13.536H13.8749L6.64288 23.872V24.608H14.0349ZM22.7481 28.448V13.152L26.3001 9.6H35.9641L39.5161 13.152V28.448L35.9641 32H26.3001L22.7481 28.448ZM34.2361 28.8L35.8041 27.264V14.336L34.2361 12.8H28.0281L26.4601 14.336V27.264L28.0281 28.8H34.2361ZM42.8619 22.24H53.4859V25.376H42.8619V22.24ZM60.7779 13.536L55.8819 16.064V12.64L61.4179 9.6H64.4899V32H60.7779V13.536ZM72.7466 13.536L67.8506 16.064V12.64L73.3866 9.6H76.4586V32H72.7466V13.536ZM80.7794 28.448V13.152L84.3314 9.6H93.9954L97.5474 13.152V28.448L93.9954 32H84.3314L80.7794 28.448ZM92.2674 28.8L93.8354 27.264V14.336L92.2674 12.8H86.0594L84.4914 14.336V27.264L86.0594 28.8H92.2674Z"
      fill="#D3D3D3"
    />
    <path
      d="M104.72 14.084V10.34L106.16 8.9H109.292L110.732 10.34V14.084L109.292 15.524H106.16L104.72 14.084ZM114.098 8.9H116.546L109.652 21.5H107.204L114.098 8.9ZM108.248 13.724L108.644 13.328V11.096L108.248 10.7H107.204L106.808 11.096V13.328L107.204 13.724H108.248ZM113.018 20.06V16.316L114.458 14.876H117.59L119.03 16.316V20.06L117.59 21.5H114.458L113.018 20.06ZM116.546 19.7L116.942 19.304V17.072L116.546 16.676H115.502L115.106 17.072V19.304L115.502 19.7H116.546Z"
      fill="#D3D3D3"
    />
    <path
      d="M107.132 24.1H108.62L111.692 32.5H110.036L109.352 30.64H106.4L105.716 32.5H104.06L107.132 24.1ZM108.992 29.296L107.876 26.104H107.852L106.772 29.296H108.992ZM112.538 24.1H117.806L119.09 25.396V28.336L117.794 29.644H114.17V32.5H112.538V24.1ZM117.062 28.276L117.482 27.856V25.888L117.062 25.468H114.17V28.276H117.062ZM126.896 29.716V32.5H125.264V30.088L124.544 29.284H121.928V32.5H120.296V24.1H125.564L126.848 25.396V27.94L126.032 28.768L126.896 29.716ZM121.928 27.94H124.82L125.24 27.52V25.888L124.82 25.468H121.928V27.94Z"
      fill="#D3D3D3"
    />
  </svg>
)
